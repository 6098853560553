import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo.js"

import * as style from "../../styles/opinions.module.scss"

const OpinionsPage = ({ data }) => {
  console.log(data)

  return (
    <Layout>
      <Seo title="Vélemények" lang="hu" />

      <div id={style.container}>
        <h1>Vélemények rólam</h1>
        <div id={style.opContainer}>
          {data.allMdx.nodes.map((node, index) => (
            <Link
              className={style.opinion}
              key={index}
              to={`/gabi-rolam-mondtak/${node.slug}`}
            >
              <div className={style.when}> {node.frontmatter.when}</div>
              <div className={style.title}>{node.frontmatter.area}</div>
              <div className={style.description}>{node.frontmatter.title}</div>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const data = graphql`
  query {
    allMdx(
      sort: { fields: frontmatter___when, order: ASC }
      filter: { fileAbsolutePath: { regex: "/(opinion)/" } }
    ) {
      nodes {
        frontmatter {
          title
          area
          when
        }
        slug
      }
    }
  }
`
export default OpinionsPage
